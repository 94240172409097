
import "@grapecity/spread-sheets/styles/gc.spread.sheets.excel2016colorful.css"
import GC from '@grapecity/spread-sheets'
import * as ExcelIO from '@grapecity/spread-excelio'
import { defineComponent, onMounted, ref, computed, nextTick } from 'vue'
import { wordingPreview, getReplacedWordingPdf, deleteReplacedWordingPdf } from '@/API/program'
import { useStore } from 'vuex'
import useUploadDraw from '@/hooks/Program/useUploadDraw'
import blobDownload from "@/utils/blobDownload";
import { useRouter } from 'vue-router'
import { getWording, getBudget2, controllingConfirmProgram } from "@/API/approve";
import { getProgramAttachment, AttachementListProps } from '@/API/setting'
import { message } from "ant-design-vue";
import Decimal from '@/utils/closing/decimal';

interface FileItem {
  uid: string;
  name?: string;
  status?: string;
  response?: string;
  url?: string;
}

interface FileInfo {
  file: FileItem;
  fileList: FileItem[];
}
export default defineComponent({
    name: 'WordingPreview',
    props: {
        wordingpreviewvisible: Boolean
    },
    setup (props, context) {
        let activeSheet: any = null;
        let sd: any = null;
        const isexpand = ref(false);
        const bu = ref("");
        const kufriVersion = ref("");
        const programCode = ref("");
        const programName = ref("");
        const programPeriod  = ref("");
        const totalBudgetVolume = ref("");
        const totalCBUVolume = ref("");
        const totalDENZAVolume = ref("");
        const totalEstVolume = ref("");
        const totalFleetVolume = ref("");
        const totalPBPVolume = ref("");
        const totalVANVolume = ref("");
        const totalVolumeList = ref<any[]>([]);
        
        GC.Spread.Sheets.LicenseKey = (ExcelIO as any).LicenseKey = 'oasis.mercedes-benz.com.cn|oasis-training.mercedes-benz.com.cn|oasis-uat.mercedes-benz.com.cn|oasis-dev.mercedes-benz.com.cn,858593977834555#B0j3vnNr3SYhRHVj5kbqtkWXRDMW3SNqFFON9GaMJ7YaV7QjVkZENjR7kXZ6RHeBpEN58WT6QUY42WeJ36ZEt4azlnZ9kXR624VtZzcrAlRTJ4LudWSMlDVul5KG3CeNNXOEZVVwInV6I6LUVTO486cwRldIdDepBHNYJ6cDJWbT36L0RXT6BFT7cTWHBHeQNncygXT59UTmh7bLdmNRtiY6RmTHRlciNnZ9ljQGtmSvUXS5J4KyUHMYRUNRRnU5lkYjN5ThJnMYZ5ayN4dMlFVtR6TmJXY5UUUrsyc9M4N9ZjNMplcxllMtRTTxxkeSZTbw3mMn5WW054LRdFelNFZvcHelNkTHFWUTV7VyUjR7hjMOJVaJdXO5plZyI6T5ZDcIVnc6NnZ55kbwFzV9cERiojITJCLiYUMGFTQDZUNiojIIJCLxIDN8ETMyETN0IicfJye&Qf35VfiUURJZlI0IyQiwiI4EjL6BCITpEIkFWZyB7UiojIOJyebpjIkJHUiwiIzATNwkDMgYDM4ATMyAjMiojI4J7QiwiIuNmLt36YuonblJWLzVGZlNmcl5mL6VGZtMXazF6bs86Yu46bj9ieuVmYtMXZkV6YyVWbuQXY55ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9yZulmbpFmc45ycpNXYvxibj9SbvNmL09WZi5yclRWZjJXZt9ycpNXYvJiOiMXbEJCLig1jlzahlDZmpnInmTYtoXpimrLjl1Yjl7KukfKplL9ilb8plTLimLiOiEmTDJCLiUTN5QzM8czN9MTO5gTN8IiOiQWSiwSflNHbhZmOiI7ckJye0ICbuFkI1pjIEJCLi4TPRR5QoFHTzgDdslEZiB5dDJGarFkR5QmNNFFcvBnYLRkWXBlfzCX';
        const router = useRouter()
        const activeWording = ref<number>(0)
        const wordingSrc = ref('');
        const attachment = ref<AttachementListProps[]>([])
        const spinning = ref<boolean>(false)
        const getColor = (level: number)=>{
            let color = "";
            switch (level)
                {
                    case 0:
                        color="#8EA9DB"
                        break;
                    case 1:
                    case 2:
                    case 3:
                    case 4:
                    case 5:
                        color="#D9E1F2"
                        break;
                    default:
                        color="#EDEDED"
                }
            return color;
        }
        

        const init = () => {
            const img = new Image();
            wordingSrc.value = ''
            if (activeWording.value === 0) {
                    spinning.value = true
                    // wording图片
                    getWording(sessionStorage.getItem('previewPgmId')).then(data => {
                        const url = window.URL.createObjectURL(data);
                        img.onload = () => {
                            window.URL.revokeObjectURL(img.src);
                        }
                        img.src = url;
                        wordingSrc.value = url;
                        spinning.value = false
                    })
            } else if (activeWording.value === 1) {
                spinning.value = true
                // // budget图片

                    nextTick(()=>{

                        getBudget2(sessionStorage.getItem('previewPgmId')).then((res: any) => {
                            bu.value = res.data.bu;
                            kufriVersion.value = res.data.kufriVersion;
                            programCode.value = res.data.programCode;
                            programName.value = res.data.programName;
                            programPeriod.value = res.data.programPeriod;
                            totalBudgetVolume.value = new Decimal(res.data.totalBudgetVolume).round(0);
                            totalCBUVolume.value = new Decimal(res.data.totalCBUVolume).round(0);
                            totalDENZAVolume.value = new Decimal(res.data.totalDENZAVolume).round(0);
                            totalEstVolume.value = new Decimal(res.data.totalEstVolume).round(0);
                            totalFleetVolume.value = new Decimal(res.data.totalFleetVolume).round(0);
                            totalPBPVolume.value = new Decimal(res.data.totalPBPVolume).round(0);
                            totalVANVolume.value = new Decimal(res.data.totalVANVolume).round(0);
                            totalVolumeList.value = res.data.totalVolumeList.map((item: {key: string; value: string}) => {
                              item.value = new Decimal(item.value).round(0)
                              return item
                            });
                            const tableDom = document.getElementById('ssHost83') as HTMLElement;
                            if (tableDom) {
                              tableDom.innerHTML = ''
                            }
                            const spread = new GC.Spread.Sheets.Workbook(tableDom);
                            spread.options.tabNavigationVisible = false
                            spread.options.tabStripVisible = false
                            const sheet = spread.getActiveSheet();
                            sheet.options.rowHeaderVisible = false;
                            // sheet.options.gridline = {color:"#FFFFFF", showVerticalGridline: true, showHorizontalGridline: true};
                            // sheet.getRange(-1, -1, -1, -1).backColor('#EDEDED')
                            const style = new GC.Spread.Sheets.Style()
                            style.backColor = '#EDEDED'
                            style.borderLeft = new GC.Spread.Sheets.LineBorder("#FFFFFF", GC.Spread.Sheets.LineStyle.thin)
                            style.borderTop = new GC.Spread.Sheets.LineBorder("#FFFFFF", GC.Spread.Sheets.LineStyle.thin)
                            style.borderRight = new GC.Spread.Sheets.LineBorder("#FFFFFF", GC.Spread.Sheets.LineStyle.thin)
                            style.borderBottom = new GC.Spread.Sheets.LineBorder("#FFFFFF", GC.Spread.Sheets.LineStyle.thin)
                            sheet.setDefaultStyle(style)
                            // 获取百分数列
                            const percentColumnKeys = [] as string[]
                            for (let i = 0; i < res.data.headerDtoList.length; i++) {
                              if (res.data.headerDtoList[i].value.indexOf('%') !== -1) {
                                percentColumnKeys.push(res.data.headerDtoList[i].key)
                              }
                            }
                            sd = res.data.dataDtoList.map((item: Record<string, string>) => {
                              for (const key in item) {
                                if (['make', 'period', 'offertype', 'sioffer', 'customer', 'typeclass', 'modelyear', 'model', 'brand'].includes(key)) {
                                  continue;
                                }
                                if (percentColumnKeys.includes(key)) {
                                  continue;
                                }
                                if (item[key] === '') {
                                  continue;
                                }
                                item[key] = new Decimal(item[key]).round(0);
                              }
                              return item;
                            });
                            const columns = [];
                            const columnWidthMap = new Map([
                              ['make', 70],
                              ['period', 110],
                              ['offertype', 80],
                              ['sioffer', 80],
                              ['customer', 70],
                              ['typeclass', 50],
                              ['brand', 50]
                            ])
                            //设置表头                    
                            for (let i = 0; i<res.data.headerDtoList.length; i++) {
                                let size = 90
                                if (percentColumnKeys.includes(res.data.headerDtoList[i].key)) {
                                  size = 60
                                } else if (columnWidthMap.has(res.data.headerDtoList[i].key)) {
                                  size = columnWidthMap.get(res.data.headerDtoList[i].key) as number
                                }
                                columns.push({
                                    size,
                                    name : res.data.headerDtoList[i].key,
                                    displayName: res.data.headerDtoList[i].value
                                });
                            }
                            sheet.autoGenerateColumns = false;
                            // 设置表头高度
                            sheet.setRowHeight(0, 80, GC.Spread.Sheets.SheetArea.colHeader);
                            // 设置列头折行
                            sheet.getRange(0, 0, 1, res.data.headerDtoList.length, GC.Spread.Sheets.SheetArea.colHeader).wordWrap(true);
                            //设置数据
                            sheet.setDataSource(sd);
                            //设置右对齐
                            for (let i = 0; i<res.data.headerDtoList.length; i++) {
                              if (!['make', 'period', 'offertype', 'sioffer', 'customer', 'typeclass', 'modelyear', 'model', 'brand'].includes(res.data.headerDtoList[i].key)) {
                                sheet.getRange(-1, i, -1, 1).hAlign(GC.Spread.Sheets.HorizontalAlign.right);
                              }
                              // sheet.autoFitColumn(i)
                            }
                            //设置层级
                            const spreadNS = GC.Spread.Sheets;
                            for (let r = 0; r < sd.length; r++) {
                                const level = sd[r].level;
                                sheet.getCell(r, 0).textIndent(level);
                            }
                            sheet.outlineColumn.options({
                                columnIndex: 0,
                                showImage: false,
                                showCheckBox: false,
                                showIndicator: true,
                                // images: ['./images/newFloder.png', './images/docFile.png', './images/archiverFolder.png'],
                                expandIndicator: require('@/assets/images/increaseIndicator.png'),
                                collapseIndicator: require('@/assets/images/decreaseIndicator.png'),
                                maxLevel: 10
                            });
                            sheet.showRowOutline(false);
                            for (let row = 0; row < sd.length; row++) {
                                if (sd[row].typeclass) {
                                    if (row + 1 < sd.length && sd[row + 1].level > sd[row].level) {
                                      sheet.rowOutlines.setCollapsed(row, true);
                                    }
                                }
                            }

                            sheet.bindColumns(columns);
                            
                            sheet.outlineColumn.refresh();
                            sheet.resumePaint();
                            activeSheet = sheet;
                            spinning.value = false
                        })
                    })
            } else if (activeWording.value === 2) {
                getProgramAttachment({params: {programId: sessionStorage.getItem('previewPgmId')}}).then(data => {
                    attachment.value = data.attachment
                })
            }
        }
        const handleClose = () => {
            context.emit('update:wordingpreviewvisible', false)
        }

        const handleWording = (acitve: number) => {
            activeWording.value = acitve
            init()

        }
        
        const handlerConfirmClick = (): void => {
            controllingConfirmProgram({ confirm: true, program: [{id: sessionStorage.getItem('previewPgmId')}] }).then(data => {
                message.success('Confirmed successfully')
            }).catch(e => {
                message.warning(e)
            })
        }
        const handlerReturnClick = (): void => {
            controllingConfirmProgram({ confirm: false, program: [{id: sessionStorage.getItem('previewPgmId')}] }).then(data => {
                message.success('Returned successfully')
            }).catch(e => {
                message.warning(e)
            })
        }
        const handleWordingDownload = () => {
            const params = {
                programId: sessionStorage.getItem('previewPgmId'),
                type: 'Internal',
                lang: 'en',
                image: false
            }
            blobDownload({url: '/pgapi/program/wording/preview_pdf', params: params})
        }

        const handleGo = () => {
            window.open(`${window.location.origin}/#/budgutinputcontrolling?pgmId=${sessionStorage.getItem('previewPgmId')}`)
        }
        onMounted(() => {
            init()
        })

        const expandExcell = ()=>{
            if (!activeSheet){
                return
            }
            if (isexpand.value){
                for (let row = 0; row < sd.length; row++) {
                    activeSheet.rowOutlines.setCollapsed(row, false);
                }
            } else {
                for (let row = 0; row < sd.length; row++) {
                    if (sd[row].typeclass) {
                        if (row + 1 < sd.length && sd[row + 1].level > sd[row].level) {
                            activeSheet.rowOutlines.setCollapsed(row, true);
                        }
                    }
                }
            }
            activeSheet.outlineColumn.refresh();
            activeSheet.resumePaint();
        }

        return {
            expandExcell,
            handleClose,
            activeWording,
            handleWording,
            handleWordingDownload,
            handleGo,
            wordingSrc,
            spinning,
            attachment,
            handlerConfirmClick,
            handlerReturnClick,
            bu,
            kufriVersion,
            programCode,
            programName,
            programPeriod,
            totalBudgetVolume,
            totalCBUVolume,
            totalDENZAVolume,
            totalEstVolume,
            totalFleetVolume,
            totalPBPVolume,
            totalVANVolume,
            totalVolumeList,
            isexpand
        }
    }
})
